<template>
  <div class="page-root tools-pgResult-root" id="tools-pgResult-root">
    <div class="pgResult-page">
      <div class="title">在线评估 > 快速评估 > <span>查询结果</span></div>
      <div class="title-sm">快速估值结果</div>
      <div class="result-item1" v-show="resSuccess">
        <div class="name1">
          {{ dataObj.vehicleTypeName }}
        </div>
        <div class="name2">
          {{
            `${dataObj.licensingDate} 上牌  | ${dataObj.city} | 行驶 ${dataObj.drivingKilometres} 万公里  |  ${dataObj.fuelType}  |  ${dataObj.engineModel}  |  ${dataObj.emission}`
          }}
        </div>
        <div class="name3">新车售价：{{ formatNum(dataObj.price) }}万</div>
        <div class="name4">
          <div>
            <div class="item">
              <div class="pifa">
                估值价格 <span>￥ </span><span class="font">{{ formatNum(dataObj.assePrice) }}</span
                ><span> 万</span>
              </div>
            </div>
<!--            <div class="item" style="color: #aeaeae">|</div>-->
<!--            <div class="item">-->
<!--              <div class="pifa">-->
<!--                零售价 <span>￥ </span><span class="font">{{ formatNum(dataObj.cSellMin) }}</span-->
<!--                ><span> 万</span>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <!-- <div class="canshu" @click="toPeizhi">参数配置 ></div> -->
        </div>
      </div>
      <div class="result-item2">
        <div class="title"><span></span> 默认车况良好情况下估值</div>
        <div id="myChart" style="width: 100%; height: 400px"></div>
        <div id="myChart-sm" style="width: 100%; height: 300px"></div>
        <div class="tips" style="color: #666; font-weight: 300">
          <span style="color: #e11919">* </span>本估值结果仅供参考，最终价格以实际车况为准。
        </div>
      </div>
      <div class="result-item2">
        <div class="title"><span></span> 未来一年的估值</div>
        <div id="myChart1" style="width: 100%; height: 400px"></div>
      </div>
      <div class="result-item2">
        <div class="title"><span></span> 我的查询记录</div>
        <el-table size="small" :data="tableData" style="width: 100%; margin-top: 10px">
          <el-table-column prop="vehicleTypeName" label="车辆名称"> </el-table-column>
          <el-table-column prop="drivingKilometres" label="公里数(万）"> </el-table-column>
          <el-table-column prop="licensingDate" label="上牌日期"> </el-table-column>
          <el-table-column prop="price" label="参考价（万）"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text"
                ><a href="#tools-pgResult-root">查看详情</a></el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 弹窗内显示进度条 -->
      <el-dialog title="估值报告加载中..." :visible.sync="dialogTableVisible" width="40%" top="40vh" :show-close="false">
        <el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" status="success"></el-progress>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import './styles/mobile.scss';
import './styles/pc/pc.scss';
import * as echarts from 'echarts';
import { get } from '@/utils/axios';

export default {
  name: 'Tools-pgResult',
  data() {
    return {
      dataObj: {},
      tableData: JSON.parse(localStorage['Tools-pgResult'] || '[]').slice(-3),
      resSuccess: false,
      dialogTableVisible: false,
      percentage: 0,
      demoData:{
        "searchValue": null,
        "createBy": null,
        "createTime": "2022-02-10 10:08:41",
        "updateBy": null,
        "updateTime": null,
        "remark": null,
        "params": {},
        "id": 1,
        "vehicleTypeId": "14861",
        "licensingDate": "2021-10",
        "cityId": "110100",
        "drivingKilometres": 10,
        "brandId": "b33b",
        "brandName": "奥迪",
        "vehicleSeriesId": "s2994s",
        "vehicleSeriesName": "奥迪RS 7",
        "vehicleTypeName": "奥迪RS 7 2014款 RS 7 4.0T Sportback",
        "price": "1788000",
        "fuelType": "汽油",
        "engineModel": "4.0T 560马力 V8",
        "emission": "欧V",
        "city": "北京市",
        "assePrice": 679440,
        "bBuyMin": 577524,
        "bBuyMax": 625084.8,
        "cSellMin": 611496,
        "cSellMax": 645468,
        "bSellMin": 706617.6,
        "bSellMax": 733795.2000000001,
        "holdMin": 494632.31999999995,
        "holdMax": 513656.64,
        "hedgeRatioMonthly": 0.99
      }
    };
  },
  methods: {
    toPeizhi() {
      this.$router.push({
        name: 'Tools-peizhi',
        query: {
          vehicleTypeId: this.dataObj.vehicleTypeId,
          createTime: this.dataObj.createTime,
          id: this.dataObj.id,
        },
      });
    },
    handleClick(row) {
      console.log(row)
      this.$router.push({
        name: 'Tools-pgResult',
        query: {
          vehicleTypeId: row.vehicleTypeId,
          licensingDate: row.licensingDate,
          cityId: row.cityId,
          drivingKilometres: row.drivingKilometres,
          city: row.city,
          id: row.id,
        },
      });
      this.init();
    },
    formatNum(num) {
      return num ? (Number(num) / 10000).toFixed(2) : '';
    },
    getMonths() {
      const currentDate = new Date(); // 获取当前日期
      const currentYear = currentDate.getFullYear(); // 获取年份
      const currentMonth = currentDate.getMonth() + 1; // 获取月份
      const result = [];
      for (let i = 0; i < 5; i++) {
        const newMonth = (currentMonth + i * 3) % 12; // 计算新的月份
        const newYear = currentYear + Math.floor((currentMonth + i * 3 - 1) / 12); // 计算新的年份
        const formattedMonth = newMonth === 0 ? '12' : newMonth.toString().padStart(2, '0'); // 如果月份为 0，则表示为 12 月
        result.push(`${newYear}.${formattedMonth}`); // 将年份和月份拼接成所需的格式
      }
      return result;
    },
    getPrice(price, ratio) {
      let priceArr = [];
      priceArr.push(this.formatNum(price));
      for (var i = 1; i <= 4; i++) {
        priceArr.push(this.formatNum(price * Math.pow(ratio, i)));
      }
      return priceArr;
    },
    init() {
      const { id } = this.$route.query;
      this.openDialog();
      if(id==1){
        const datas = this.demoData
        this.dataObj = datas;
        var myChart = echarts.init(document.getElementById('myChart'));
        var myChartsm = echarts.init(document.getElementById('myChart-sm'));
        var myChart1 = echarts.init(document.getElementById('myChart1'));
        // 绘制柱状图表
        myChart.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: ['车商收车价', '个人交易价', '车商卖车价', '抵押贷款价'],
          },
          series: [
            {
              name: '最高价(万元)',
              type: 'bar',
              label: {
                show: true,
              },
              itemStyle: {
                normal: {
                  color: (params) => {
                    let list = ['#7892EA', '#71DFEC', '#F9A15D', '#FFC86D'];
                    return list[params.dataIndex];
                  },
                },
              },
              data: [
                this.formatNum(datas.bBuyMax),
                this.formatNum(datas.cSellMax),
                this.formatNum(datas.bSellMax),
                this.formatNum(datas.holdMax),
                ,
              ],
            },
            {
              name: '最低价(万元)',
              type: 'bar',
              label: {
                show: true,
              },
              itemStyle: {
                normal: {
                  color: (params) => {
                    let list = ['#7892EA', '#71DFEC', '#F9A15D', '#FFC86D'];
                    return list[params.dataIndex];
                  },
                },
              },
              data: [
                this.formatNum(datas.bBuyMin),
                this.formatNum(datas.cSellMin),
                this.formatNum(datas.bSellMin),
                this.formatNum(datas.holdMin),
              ],
            },
          ],
        });
        myChartsm.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
          },
          xAxis: {
            data: ['车商收车', '个人交易', '车商卖车', '抵押贷款'],
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: '最高价(万元)',
              type: 'bar',
              label: {
                show: true,
                position: 'top',
              },
              itemStyle: {
                normal: {
                  color: (params) => {
                    let list = ['#7892EA', '#71DFEC', '#F9A15D', '#FFC86D'];
                    return list[params.dataIndex];
                  },
                },
              },
              data: [
                this.formatNum(datas.bBuyMax),
                this.formatNum(datas.cSellMax),
                this.formatNum(datas.bSellMax),
                this.formatNum(datas.holdMax),
                ,
              ],
            },
            {
              name: '最低价(万元)',
              type: 'bar',
              label: {
                show: true,
                position: 'top',
              },
              itemStyle: {
                normal: {
                  color: (params) => {
                    let list = ['#7892EA', '#71DFEC', '#F9A15D', '#FFC86D'];
                    return list[params.dataIndex];
                  },
                },
              },
              data: [
                this.formatNum(datas.bBuyMin),
                this.formatNum(datas.cSellMin),
                this.formatNum(datas.bSellMin),
                this.formatNum(datas.holdMin),
              ],
            },
          ],
        });
        myChart1.setOption({
          tooltip: {
            trigger: 'item',
            formatter: `{b} / {c}万元`,
          },
          xAxis: {
            data: this.getMonths(),
          },
          yAxis: {},
          series: [
            {
              data: this.getPrice(Number(datas.assePrice), datas.hedgeRatioMonthly),
              type: 'line',
              itemStyle: {
                normal: {
                  color: '#FBA518',
                  lineStyle: {
                    color: '#FBA518',
                  },
                },
              },
              label: {
                // show: true,
                position: 'bottom',
                textStyle: {
                  value: 12,
                  fontSize: 12,
                },
              },
            },
          ],
        });
        this.closeDialog();
      }else{
        get(`/web/asse/${id}`).then((res) => {
          const datas = res.data;
          console.log(datas)
          if (localStorage['Tools-pgResult']) {
            const pgResult = JSON.parse(localStorage['Tools-pgResult']);
            if (!pgResult.map((v) => v.id).includes(datas.id)) {
              localStorage['Tools-pgResult'] = JSON.stringify([...pgResult, datas]);
            }
          } else {
            localStorage['Tools-pgResult'] = JSON.stringify([datas]);
          }
          this.dataObj = datas;
          var myChart = echarts.init(document.getElementById('myChart'));
          var myChartsm = echarts.init(document.getElementById('myChart-sm'));
          var myChart1 = echarts.init(document.getElementById('myChart1'));
          // 绘制柱状图表
          myChart.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['车商收车价', '个人交易价', '车商卖车价', '抵押贷款价'],
            },
            series: [
              {
                name: '最高价(万元)',
                type: 'bar',
                label: {
                  show: true,
                },
                itemStyle: {
                  normal: {
                    color: (params) => {
                      let list = ['#7892EA', '#71DFEC', '#F9A15D', '#FFC86D'];
                      return list[params.dataIndex];
                    },
                  },
                },
                data: [
                  this.formatNum(datas.bBuyMax),
                  this.formatNum(datas.cSellMax),
                  this.formatNum(datas.bSellMax),
                  this.formatNum(datas.holdMax),
                  ,
                ],
              },
              {
                name: '最低价(万元)',
                type: 'bar',
                label: {
                  show: true,
                },
                itemStyle: {
                  normal: {
                    color: (params) => {
                      let list = ['#7892EA', '#71DFEC', '#F9A15D', '#FFC86D'];
                      return list[params.dataIndex];
                    },
                  },
                },
                data: [
                  this.formatNum(datas.bBuyMin),
                  this.formatNum(datas.cSellMin),
                  this.formatNum(datas.bSellMin),
                  this.formatNum(datas.holdMin),
                ],
              },
            ],
          });
          myChartsm.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
            },
            xAxis: {
              data: ['车商收车', '个人交易', '车商卖车', '抵押贷款'],
            },
            yAxis: {
              type: 'value',
            },
            series: [
              {
                name: '最高价(万元)',
                type: 'bar',
                label: {
                  show: true,
                  position: 'top',
                },
                itemStyle: {
                  normal: {
                    color: (params) => {
                      let list = ['#7892EA', '#71DFEC', '#F9A15D', '#FFC86D'];
                      return list[params.dataIndex];
                    },
                  },
                },
                data: [
                  this.formatNum(datas.bBuyMax),
                  this.formatNum(datas.cSellMax),
                  this.formatNum(datas.bSellMax),
                  this.formatNum(datas.holdMax),
                  ,
                ],
              },
              {
                name: '最低价(万元)',
                type: 'bar',
                label: {
                  show: true,
                  position: 'top',
                },
                itemStyle: {
                  normal: {
                    color: (params) => {
                      let list = ['#7892EA', '#71DFEC', '#F9A15D', '#FFC86D'];
                      return list[params.dataIndex];
                    },
                  },
                },
                data: [
                  this.formatNum(datas.bBuyMin),
                  this.formatNum(datas.cSellMin),
                  this.formatNum(datas.bSellMin),
                  this.formatNum(datas.holdMin),
                ],
              },
            ],
          });
          myChart1.setOption({
            tooltip: {
              trigger: 'item',
              formatter: `{b} / {c}万元`,
            },
            xAxis: {
              data: this.getMonths(),
            },
            yAxis: {},
            series: [
              {
                data: this.getPrice(Number(datas.assePrice), datas.hedgeRatioMonthly),
                type: 'line',
                itemStyle: {
                  normal: {
                    color: '#FBA518',
                    lineStyle: {
                      color: '#FBA518',
                    },
                  },
                },
                label: {
                  // show: true,
                  position: 'bottom',
                  textStyle: {
                    value: 12,
                    fontSize: 12,
                  },
                },
              },
            ],
          });
        }).then(() => {
          this.closeDialog();
        });;
      }
    },
    openDialog(){
      this.dialogTableVisible = true
      let percentage = 0;
      const interval = setInterval(() => {
        if (percentage < 100) {
          percentage += 10;
          if (percentage >= 100) {
            percentage = 99;
          }
          // 更新进度条
          this.percentage = percentage;
        } else {
          // 如果进度达到100%，清除定时器
          clearInterval(interval);
        }
      }, 1000);
    },
    closeDialog(){
      this.percentage = 100;
      this.resSuccess = true
      this.dialogTableVisible = false
    },
  },
  mounted() {
    document.getElementById('app').scrollTo(0, 0);
    this.init();
  },
};
</script>
